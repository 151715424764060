import { Spin } from 'antd';
import React, { useState, useEffect } from 'react';


const Index = (props) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100px' }}>
            <Spin></Spin>
        </div>
    )
}

export default Index;