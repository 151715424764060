
import intl from 'react-intl-universal';
import Loadable from 'react-loadable';
import Loading from "./Loading/Loading"

const Login = Loadable({
    loader: () => import('./Login/Login'),
    loading: Loading
});
const LoginByToken = Loadable({
    loader: () => import('./LoginByToken/LoginByToken'),
    loading: Loading
});
const Home = Loadable({
    loader: () => import('./Home/Home'),
    loading: Loading
});
const TiKongSet = Loadable({
    loader: () => import('./Home/pages/TiKongSet/TiKongSet'),
    loading: Loading
});

const XcxUploadManage = Loadable({
    loader: () => import('./Home/pages/a/XcxUploadManage/XcxUploadManage'),
    loading: Loading
});

export default [
    {
        path: '/',
        redirect: "/home/TiKongSet",
    },
    {
        path: '/login',
        component: Login,
    },
    {
        path: '/LoginByToken',
        component: LoginByToken,
    },
    {
        path: '/home',
        // layout: false,
        component: Home,
        // icon: "icon-qunfengliansuodian",
        layout: {
            // hideMenu: true,
            // hideNav: true,
            // hideFooter: true,
        },
        routes: [
            {
                name: intl.get("a").defaultMessage('梯控设置'),
                path: "/home/TiKongSet",
                // icon: "icon-qunfengliansuodian",
                // access: 'normalRouteFilter',
                component: TiKongSet,
                layout: {
                    // hideMenu: true,
                    // hideNav: true,
                    // hideFooter: true,
                },
            },
            {
                name: intl.get("a").defaultMessage('小程序上传管理'),
                path: "/home/servicer/XcxUploadManage",
                // icon: "icon-qunfengliansuodian",
                // access: 'normalRouteFilter',
                component: XcxUploadManage,
                layout: {
                    // hideMenu: true,
                    // hideNav: true,
                    // hideFooter: true,
                },
            },
        ]
    },

]