export default function access(currentUser) {
    const auth_list = currentUser ? currentUser.auth_list || [] : []
    const auth_list_ele = auth_list.filter((item) => {
        return item.auth_type === 'element'
    })
    let ele_auth = {}
    auth_list_ele.forEach(item => {
        ele_auth[item.auth_mark] = true
    });

    // console.log(ele_auth)
    const hasRoutes = auth_list.map((item) => {
        return item.router_url
    })
    // console.log(hasRoutes)
    return {
        ...ele_auth,
        canAdmin: currentUser && currentUser.name === 'admin',
        normalRouteFilter: (route) => {
            return hasRoutes.includes(route.path)
        },
        hasPageAuth: (path) => {
            return hasRoutes.includes(path)
        }
    };
}
