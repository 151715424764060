// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/antd/dist/antd.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    /* width: 100vw;
    height: 100vh;
    border: 1px solid red; */
}

#components-layout-demo-responsive .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.2);
}

.site-layout-sub-header-background {
    /* background: #fff; */
}

.site-layout-background {
    background: #fff;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;IACI;;4BAEwB;AAC5B;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,oCAAoC;AACxC;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["@import '~antd/dist/antd.css';\r\n\r\n.App {\r\n    /* width: 100vw;\r\n    height: 100vh;\r\n    border: 1px solid red; */\r\n}\r\n\r\n#components-layout-demo-responsive .logo {\r\n    height: 32px;\r\n    margin: 16px;\r\n    background: rgba(255, 255, 255, 0.2);\r\n}\r\n\r\n.site-layout-sub-header-background {\r\n    /* background: #fff; */\r\n}\r\n\r\n.site-layout-background {\r\n    background: #fff;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
