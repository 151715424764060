import React, { useState, useEffect, createContext } from 'react';
import logo from './logo.svg';
import './App.css';
import { Button, Layout, Menu } from 'antd';

import { Route, Routes, BrowserRouter, HashRouter, Navigate, NavLink, useLocation, useFetcher } from 'react-router-dom'

import routes from "./routes"
import access from './access';

const AppContext = createContext()
export { AppContext }

const App = (props) => {
  const { search, pathname } = useLocation()
  const [token, setToken] = useState('')

  const [admin_msg, setAdmin_msg] = useState({});

  const [routesAuth, setRoutesAuth] = useState([]);
  const [pages, setPages] = useState([]);
  const [page_data, setPage_data] = useState({});
  const [menu, setMenu] = useState([]);

  const [hideMenu, setHideMenu] = useState(false);
  const [hideNav, setHideNav] = useState(false);
  const [hideFooter, setHideFooter] = useState(false);
  const [hidePadding, setHidePadding] = useState(false);

  //获取路由参数
  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    let token2 = searchParams.get('token');
    setToken(token2);
    localStorage['token'] = token2
  }, [search]);

  // 初始化用户登录数据
  useEffect(() => {
    if (localStorage['admin_msg'] && localStorage['admin_msg'] != 'null' && localStorage['admin_msg'] != 'undefined') {
      let admin_msg2 = JSON.parse(localStorage['admin_msg']);
      setAdmin_msg(admin_msg2)
    }
  }, []);

  //根据用户数据计算路由权限
  useEffect(() => {
    if (admin_msg) {
      let routesAuth2 = get_access_res(routes, access, admin_msg)
      setRoutesAuth(routesAuth2)
    }
  }, [admin_msg]);

  //获取所有页面数据
  useEffect(() => {
    let pages = get_all_page(routesAuth)
    setPages(pages)
  }, [routesAuth]);


  // 根据当前路由和所有页面列表获取当前页面数据
  useEffect(() => {
    let page_data = {}
    for (let item of pages) {
      if (item.path === pathname) {
        page_data = item
        break
      }
    }
    setPage_data(page_data)
  }, [pathname, pages]);


  // 根据所选择页面数据 判断 布局情况
  useEffect(() => {
    if (page_data.layout === false) {
      setHideMenu(true)
      setHideNav(true)
      setHideFooter(true)
      setHidePadding(true)
    } else {
      setHideMenu(false)
      setHideNav(false)
      setHideFooter(false)
      setHidePadding(false)

    }
    if (page_data.layout) {
      if (page_data.layout.hideNav) {
        setHideMenu(true)
      } else {
        setHideMenu(false)
      }
      if (page_data.layout.hideMenu) {
        setHideMenu(true)
      } else {
        setHideMenu(false)
      }
      if (page_data.layout.hideFooter) {
        setHideFooter(true)
      } else {
        setHideFooter(false)
      }
      if (page_data.layout.hidePadding) {
        setHidePadding(true)
      } else {
        setHidePadding(false)
      }
    }
  }, [page_data]);

  // 若菜单状态为显示 计算菜单数据
  useEffect(() => {
    if (!hideMenu) {
      function get_show_menu(routes) {
        function get_item(routes) {
          let routes2 = []
          routes.forEach((item) => {
            if (item.access_res) {
              if (item.routes) {
                routes2.push({
                  ...item,
                  routes: get_item(item.routes)
                })
              } else {
                routes2.push(item)
              }
            }

          })
          return routes2
        }
        let rs = get_item(routes)
        return rs
      }
      let homeIndex = 0
      for (let i = 0; i < routesAuth.length; i++) {
        if (routesAuth[i].path === '/home') {
          homeIndex = i
          break
        }
      }
      let menu2 = get_show_menu(routesAuth.length > 0 ? routesAuth[homeIndex].routes : [])
      setMenu(menu2)
    }
  }, [hideMenu, routesAuth]);

  let html = render_routes(routesAuth)
  return (
    <AppContext.Provider
      value={{
        search, pathname,
        token, setToken,
        admin_msg, setAdmin_msg,

        routesAuth, setRoutesAuth,
        pages, setPages,
        page_data, setPage_data,
        menu, setMenu,

        hideMenu, setHideMenu,
        hideNav, setHideNav,
        hideFooter, setHideFooter,
        hidePadding, setHidePadding
      }}
    >
      <Routes>
        {html}
      </Routes>
    </AppContext.Provider>
  );
}

function get_access_res(routes, access, admin_msg) {
  return routes.map((item) => {
    if (item.routes) {
      if (item.access) {
        return {
          ...item,
          access_res: access(admin_msg)[item.access](item),
          routes: get_access_res(item.routes, access, admin_msg)
        }
      } else {
        return {
          ...item,
          access_res: true,
          routes: get_access_res(item.routes, access, admin_msg)
        }
      }
    } else {
      if (item.access) {
        return {
          ...item,
          access_res: access(admin_msg)[item.access](item)
        }
      } else {
        return {
          ...item,
          access_res: true
        }
      }
    }
  })
}

function get_all_page(routes) {
  function get_list(routes) {
    let pages = []
    routes.forEach((item) => {
      if (item.component || item.microapp) {
        pages.push(item)
      }
      if (item.routes) {
        let temp = get_list(item.routes)
        pages = pages.concat(temp)
      }
    })
    return pages
  }
  let pages = get_list(routes)
  return pages
}


const render_routes = (routes) => {
  return routes.map((item, index, arr) => {
    if (item.redirect) {
      return (
        <Route path={item.path} key={item.path} element={<Navigate to={item.redirect} />} >
          {
            item.routes && render_routes(item.routes)
          }
        </Route>
      )
    } else {
      return (
        <Route path={item.path} key={item.path} element={item.access_res ? <item.component /> : <NoAuth />} >
          {
            item.routes && render_routes(item.routes)
          }
        </Route>
      )
    }
  })
}

const NoAuth = () => {
  return (
    <div style={{ color: 'red' }} >无权限访问</div>
  )
}

export default App;
